import "styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import type { NextWebVitalsMetric } from "next/app";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { DefaultSeo } from "next-seo";
import Head from "next/head";
import Link from "next/link";
import Script from "next/script";
import { GoogleAnalytics, event } from "nextjs-google-analytics";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";

import { AuthProvider } from "context/AuthContext";
import ProtectedRoute from "components/common/ProtectedRoute";
import { PROTECTED_ROUTES } from "constants/protectedRoutes";
import SEO from "config/seo";

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  event(name, {
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [loadGA, setLoadGA] = useState(false);

  useEffect(() => {
    const onMouseMove = () => document.body.classList.remove("focus-visible");
    const onKeyDown = ({ key }) =>
      key === "Tab" && document.body.classList.add("focus-visible");

    window.addEventListener("mousemove", () => onMouseMove);

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  useEffect(() => {
    if (getCookieConsentValue() === "true") setLoadGA(true);
  }, []);

  const getDefaultConsent = () => {
    return getCookieConsentValue() === "true" ? "granted" : "denied";
  };

  return (
    <AuthProvider>
      {loadGA && (
        <GoogleAnalytics trackPageViews defaultConsent={getDefaultConsent()} />
      )}

      <Head>
        <link rel="icon" href="favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no maximum-scale=1.0"
        />
      </Head>
      <DefaultSeo {...SEO} />
      {PROTECTED_ROUTES.includes(router.pathname) ? (
        <ProtectedRoute>
          <Component {...pageProps} />
        </ProtectedRoute>
      ) : (
        <Component {...pageProps} />
      )}
      <CookieConsent
        acceptOnScrollPercentage={30}
        enableDeclineButton
        buttonText="Accept"
        declineButtonText="Decline"
        buttonStyle={{
          background: "#059669",
          color: "#fff",
          borderRadius: "5px",
          padding: "10px 20px",
        }}
        declineButtonStyle={{
          background: "#9ca3af",
          color: "#fff",
          borderRadius: "5px",
          padding: "10px 20px",
        }}
        contentStyle={{ flex: "" }}
        acceptOnScroll={true}
        style={{
          background: "#fafafa",
          color: "#0f172a",
          zIndex: 2147483648,
          paddingRight: "10%",
          justifyContent: "start",
        }}
      >
        This website uses{" "}
        <Link href="/privacy" className="cursor-pointer underline">
          cookies
        </Link>{" "}
        to enhance the user experience.
      </CookieConsent>
    </AuthProvider>
  );
}

export default App;
