import { DefaultSeoProps } from "next-seo";

import { BASE_URL } from "constants/redirects";

const config: DefaultSeoProps = {
  title: "Act Now Button",
  description:
    "Create actionable content. Discover and generate action buttons for your website.",
  canonical: BASE_URL,
  openGraph: {
    url: BASE_URL,
    title: "Act Now Button",
    description:
      "Create actionable content. Discover and generate an action buttons for your website.",
    images: [
      {
        url: `${BASE_URL}/actnowbutton-meta-image.jpg`,
        width: 1200,
        height: 630,
        alt: "Act Now Button - Create actionable content. Discover and generate an action buttons for your website.",
        type: "image/webp",
      },
    ],
    siteName: "Act Now Button",
  },
  twitter: {
    handle: "@actnowbutton",
    site: "@actnowbutton",
    cardType: "summary_large_image",
  },
};

export default config;
