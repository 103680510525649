import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  signOut,
  UserCredential,
} from "@firebase/auth";

import { auth } from "utils/firebase";
import {
  doesUserExist,
  getUser,
  getWaitlistUser,
  addToWaitlist,
  updateUser,
} from "pages/api/user";
import { ErrorWithCode } from "models/ErrorWithCode";
import { ERROR_CODES } from "constants/errorCodes";
import { FirebaseError } from "firebase/app";

const AuthContext = createContext<{
  user: {
    uid: string;
    email: string | null;
    displayName: string | null;
    name: string | null;
    username: string | null;
    photoURL: string | null;
    coverImageUrl: string | null;
  } | null;

  login: (email: string, password: string) => Promise<UserCredential>;
  getGoogleCredentials: () => Promise<UserCredential>;
  setShouldRemember: (shouldRemember: boolean) => void;
  logout: () => Promise<void>;
  registerWithEmail: (
    email: string,
    password: string
  ) => Promise<UserCredential>;
  registerWithGoogle: (credentials: UserCredential) => Promise<UserCredential>;
  validateUser: (credentials: UserCredential) => Promise<boolean>;
}>({
  user: null,

  login: (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password),
  getGoogleCredentials: () => signInWithPopup(auth, new GoogleAuthProvider()),
  setShouldRemember: (shouldRemember: boolean) => {},
  logout: () => Promise.resolve(),
  registerWithEmail: (email, password) =>
    createUserWithEmailAndPassword(auth, email, password),
  registerWithGoogle: null,
  validateUser: null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<{
    uid: string;
    email: string | null;
    displayName: string | null;
    name: string | null;
    username: string | null;
    photoURL: string | null;
    coverImageUrl: string | null;
  } | null>(null);

  // TODO maybe https://colinhacks.com/essays/nextjs-firebase-authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        getUser(authUser.uid)
          .then((user) => {
            setUser({
              uid: authUser.uid,
              email: authUser.email,
              displayName: authUser.displayName,
              photoURL: user?.profileImageUrl || authUser.photoURL,
              coverImageUrl: user?.coverImageUrl,
              name: user?.name,
              username: user?.username,
            });
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // listen for token changes
  // call setUser and write new token as a cookie
  // useEffect(() => {
  //   return onAuthStateChanged(auth, async (authUser) => {
  //     if (!authUser) {
  //       setUser(null);
  //       setLoading(false);
  //       nookies.set(undefined, "token", "", { path: "/" });
  //     } else {
  //       const token = await authUser.getIdToken();
  //       getUser(authUser.uid)
  //         .then((user) => {
  //           setUser({
  //             uid: authUser.uid,
  //             email: authUser.email,
  //             displayName: authUser.displayName,
  //             photoURL: user?.profileImageUrl || authUser.photoURL,
  //             coverImageUrl: user?.coverImageUrl,
  //             name: user?.name,
  //             username: user?.username,
  //           });
  //           nookies.set(undefined, "token", token, { path: "/" });
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         })
  //         .finally(() => {
  //           setLoading(false);
  //         });
  //     }
  //   });
  // }, []);

  // // force refresh the token every 10 minutes
  // useEffect(() => {
  //   const handle = setInterval(async () => {
  //     const user = auth.currentUser;
  //     if (user) await user.getIdToken(true);
  //   }, 10 * 60 * 1000);

  //   // clean up setInterval
  //   return () => clearInterval(handle);
  // }, []);

  // const addToWaitlist = async (email: string) => {
  //   return await fetch("https://api.getwaitlist.com/api/v1/waiter", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       email: email,
  //       api_key: process.env.NEXT_PUBLIC_WAITLIST_API_KEY,
  //     }),
  //   });
  // };

  const validateUser = async (
    credentials: UserCredential
  ): Promise<boolean> => {
    if (!credentials.user.email)
      throw new Error("Could not retrieve email address.");

    return await doesUserExist(credentials.user.email);
  };

  const login = async (
    email: string,
    password: string
  ): Promise<UserCredential> => {
    try {
      const credentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (!(await validateUser(credentials))) {
        throw new ErrorWithCode(ERROR_CODES.notFound);
      }

      return credentials;
    } catch (err) {
      if (
        (err instanceof FirebaseError && err.code === "auth/user-not-found") ||
        (err instanceof ErrorWithCode && err.code === ERROR_CODES.notFound)
      ) {
        throw new ErrorWithCode(ERROR_CODES.notFound);
      }

      if (err instanceof ErrorWithCode) throw err;

      if (err instanceof FirebaseError) {
        if (err.code === "auth/wrong-password") {
          throw new ErrorWithCode(ERROR_CODES.wrongPassword);
        }
      }

      console.error(err);
      throw new Error("Something went wrong");
    }
  };

  const getGoogleCredentials = async (): Promise<UserCredential> => {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(auth, provider);
  };

  const setShouldRemember = async (setShouldRemember: boolean) => {
    setShouldRemember
      ? await setPersistence(auth, browserLocalPersistence)
      : await setPersistence(auth, browserSessionPersistence);
  };

  const logout = async () => {
    setUser(null);

    return await signOut(auth);
  };

  const registerWithGoogle = async (
    credentials: UserCredential
  ): Promise<UserCredential> => {
    // WAITLIST DISABLED
    // const waitlistUser = await getWaitlistUser(credentials.user.email);
    // if (waitlistUser == null) {
    //   throw new ErrorWithCode(ERROR_CODES.waitlistUserNotFound);
    // }

    // if (waitlistUser.removed_from_waitlist === false) {
    //   throw new ErrorWithCode(ERROR_CODES.waitlistUserNotOffboarded);
    // }

    return credentials;
  };

  const registerWithEmail = async (
    email: string,
    password: string
  ): Promise<UserCredential> => {
    // WAITLIST DISABLED
    // const waitlistUser = await getWaitlistUser(email);
    // if (waitlistUser == null) {
    //   throw new ErrorWithCode(ERROR_CODES.waitlistUserNotFound);
    // }

    // if (waitlistUser.removed_from_waitlist === false) {
    //   throw new ErrorWithCode(ERROR_CODES.waitlistUserNotOffboarded);
    // }

    const credentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    ).catch((err) => {
      if (err instanceof FirebaseError) {
        if (err.code === "auth/email-already-in-use") {
          throw new ErrorWithCode(ERROR_CODES.alreadyRegistered);
        }
      }

      throw err;
    });

    return credentials;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        validateUser,
        getGoogleCredentials,
        setShouldRemember,
        logout,

        registerWithEmail,
        registerWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }

  return context;
};
